@import "../../sass/default/variable";
.st-btn.st-style1{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5em;
  font-weight: 500;
  padding: 12px 1.5em;
  border-radius: 50px;
  min-width: 170px;
  border: none;
  outline: none;
  transition: all 0.4s ease;
  text-transform: capitalize;
}

.st-btn.st-style1:hover {
  transform: translateY(-1px);
  box-shadow: 0px 5px 10px 0px #fec64450;
  cursor: pointer;
}

.st-btn.st-style1.st-color1 {
  background-color: $base-color1;
  color: $dark-color2;
}

.st-hero-btn-group {
  .st-btn:not(:last-child) {
    margin-right: 12px;
  }
}

@media screen and (max-width: 991px) {
  .st-btn.st-style1 {
    font-size: 14px;
    padding: 0.72em 1.8em;
  }
}
