@import "../../sass/default/variable";

.st-hero-wrap {
  position: relative;
  height: 850px;
  background-color: #070d1b;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .st-hero-img {
    position: absolute;
    right: 4%;
    top: 0;
    height: 100%;
    width: 45%;
    z-index: 11;
  }
}

.st-hero.st-style1 {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  z-index: 11;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  .st-hero-text {
    h1 {
      font-size: 120px;
      margin-bottom: 24px;
      font-weight: 900;
      color: $base-color3;
      line-height: 1;
    }

    h2 {
      font-size: 36px;
      margin-bottom: 41px;
      font-weight: 300;
      color: $base-color2;
    }

    h3 {
      font-size: 30px;
      margin-top: -6px;
      margin-bottom: 10px;
      font-weight: 300;
      color: $base-color1;
    }
  }
}

.st-social-group {
  background-color: $dark-color4;
  border: 1px solid $base-color4;
  border-right: 0;
  border-radius: 50px 0 0 50px;
  height: 80px;
  width: 448px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 30px;
  padding-left: 12px;
  right: 100%;
}

// #particles-js {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   opacity: 0.7;
//   top: 0;
//   left: 0;
// }

.st-hero-social-links {
  display: flex;
  justify-content: center;

  .st-social-btn {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 0 10px;

    &:hover {
      border-color: rgba(255, 255, 255, 0.6);
      color: rgba(255, 255, 255, 0.9);
    }
  }
}

.st-to-right {
  transition: all 0.3s ease;
}

@media screen and (max-width: 991px) {
  .st-hero-wrap {
    height: 700px;

    .st-hero-img {
      display: none;
    }
  }
}

@media screen and (max-width: 575px) {
  .st-hero.st-style1 {
    .st-hero-text h1 {
      font-size: 70px;
    }
  }
}
